import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    registerDataSources: [],
    registeredDataSources: [],
    graduationProjectRegisterRounds: [],
    totalRows: 0,
    conditionRegisterGraduationProject: true,
  },
  getters: {
    graduationProjectRegisterRounds: state => state.graduationProjectRegisterRounds,
    totalRows: state => state.totalRows,
    registerDataSources: state => state.registerDataSources,
    conditionRegisterGraduationProject: state => state.conditionRegisterGraduationProject,
    registeredDataSources: state => state.registeredDataSources,
  },
  mutations: {
    SET_REGISTER_DATA_SOURCES: (state, data) => { state.registerDataSources = data },
    SET_CHECK_REGISTER: (state, conditionRegisterGraduationProject) => { state.conditionRegisterGraduationProject = conditionRegisterGraduationProject },
    SET_REGISTERED_DATA_SOURCES: (state, registeredDataSources) => { state.registeredDataSources = registeredDataSources },
    SET_GRADUATION_PROJECT_REGISTER_ROUNDS: (state, { total, records }) => {
      state.totalRows = total
      state.graduationProjectRegisterRounds = records
    },
  },
  actions: {
    async getGraduationProjectRegisterRounds({ commit }, params) {
      try {
        const response = await axios.get('uni/graduationProjectRegisterRounds', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_GRADUATION_PROJECT_REGISTER_ROUNDS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDataSources({ commit }, params) {
      try {
        const response = await axios.get('uni/portals/getRegisterGraduationProjects', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { registerSubjects, registeredSubjects } = data.data
          commit('SET_REGISTER_DATA_SOURCES', registerSubjects)
          commit('SET_REGISTERED_DATA_SOURCES', registeredSubjects)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line consistent-return
    async checkConditionRegisterGraduationProject({ commit }, params) {
      try {
        const response = await axios.get('uni/portals/checkConditionRegisterGraduationProject', { params })
        const { data } = response
        commit('SET_CHECK_REGISTER', data.data)
      } catch (e) {
        handleCatch(e)
      }
    },
    async createStudentRegisterSubject({ commit }, params) {
      try {
        const response = await axios.post('uni/portals/studentRegisterGraduationProject', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteStudentRegisteredSubject({ commit }, id) {
      try {
        const response = await axios.delete(`uni/portals/deleteStudentRegisteredGraduationProject/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
