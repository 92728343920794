export const OSType = Object.freeze({
  WEB: 3,
})

export const LOGIN_TYPE = Object.freeze({
  NORMAL: '1',
  SSO: '2',
})

export const OBJECT_GROUP_TYPES = [
  { value: 1, label: 'Sinh viên lớp tín chỉ' },
  { value: 2, label: 'Sinh viên, giảng viên và cán bộ' },
]

export const CRITERIA_TYPES = [
  { value: 1, label: 'Thanh kéo điểm' },
  { value: 2, label: 'Lựa chọn' },
  { value: 3, label: 'Tự luận' },
]

export const MULTIPLE_CRITERIA_OPTION_TYPES = [
  { value: 0, label: 'Không' },
  { value: 1, label: 'Có' },
]

export const REQUIRED = [
  { value: 0, label: 'Không bắt buộc trả lời' },
  { value: 1, label: 'Có bắt buộc trả lời' },
]
